//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'

export default {
  components: {
    'close-icon': createSVGComponent({ icon: 'close', size: 16 }),
    'search-icon': createSVGComponent({ icon: 'search', size: 20 }),
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    showSearchInput: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClose () {
      if (this.value.trim().length === 0) {
        this.$emit('hide')
      } else {
        this.$emit('search', '')
        this.focusInput()
      }
    },
    openSearchInput () {
      this.$emit('show')
      window.setTimeout(() => this.focusInput(), 301)
    },
    focusInput () {
      this.$refs.searchInput && this.$refs.searchInput.focus()
    },
    search (event) {
      this.$emit('search', event ? event.target.value : '')
    },
    handleInput (event) {
      this.$emit(
        'input',
        event ? event.target.value : '',
      )
    },
  },
}
